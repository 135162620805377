







































































































































































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import firebase from "@/plugins/firebase";
import "@/types";
import Confirm from "@/components/Confirm.vue";
import CKEditor from "@ckeditor/ckeditor5-vue2";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

@Component({
  components: {
    Confirm,
    ckeditor: CKEditor.component
  }
})
export default class AppDetail extends Vue {
  appId = "";

  app: ScholarshipApplication | null = null;
  scholarship: Scholarship | null = null;

  eduLevels = [
    "Okul Öncesi",
    "İlköğretim",
    "Lise",
    "Üniversite",
    "Tıp/Diş Hekimliği"
  ];

  gender = ["Kadın", "Erkek"];

  schoolTypes = [
    "Okul Öncesi",
    "İlköğretim",
    "Lise",
    "Üniversite (2 Yıllık)",
    "Üniversite (4 Yıllık)",
    "Üniversite (5 Yıllık)",
    "Tıp/Diş Hekimliği"
  ];

  residentialTypes = ["Aile Yanı", "Ev", "Devlet Yurdu", "Özel Yurt"];

  chronicIllness = ["Var", "Yok"];

  isAlive = ["Hayatta", "Hayatta Değil"];

  status = [
    {
      text: "Kabul",
      value: "accepted"
    },
    {
      text: "Red",
      value: "rejected"
    },
    {
      text: "Devam ediyor",
      value: "inprogress"
    },
    {
      text: "Değerlendiriliyor",
      value: "inevaluation"
    },
    {
      text: "Belge Eksik",
      value: "missing"
    }
  ];

  get motherIsAlive() {
    return this.app?.form.family.mother.isAlive === "Hayatta";
  }

  get fatherIsAlive() {
    return this.app?.form.family.father.isAlive === "Hayatta";
  }

  get statusMessage(): {
    subject: string;
    body: string;
  } {
    // Kayıtlı mesaj yoksa varsayılan mesajları kullan
    switch (this.app?.status) {
      case "accepted":
        return {
          subject: "KAHEV Burs Başvurunuz Kabul Edildi",
          body: `<p>Merhaba,</p><p><strong>{{tarih}}</strong> tarihinde <strong>{{ogrenci}}</strong> adına yaptığınız <strong>{{id}}</strong> kodlu burs başvurusu <strong>onaylandı</strong>.</p><p>Süreç ile ilgili olarak sizinle en kısa sürede iletişime geçeceğiz.</p><p>İyi günler!</p><p>KAHEV</p>`
        };
      case "rejected":
        return {
          subject: "KAHEV Burs Başvurunuz Maalesef Reddedildi",
          body: `<p>Merhaba,</p><p><strong>{{tarih}}</strong> tarihinde <strong>{{ogrenci}}</strong> adına yaptığınız <strong>{{id}}</strong> kodlu burs başvurusu maalesef <strong>reddedildi</strong>.</p><p>İyi günler!</p><p>KAHEV</p>`
        };
      case "inprogress":
        return {
          subject: "KAHEV Burs Başvurunuza Devam Edebilirsiniz",
          body: `<p>Merhaba,</p><p><strong>{{tarih}}</strong> tarihinde <strong>{{ogrenci}}</strong> adına yaptığınız <strong>{{id}}</strong> kodlu burs başvurunuzun durumu <strong>devam ediyor</strong> olarak güncellendi.</p><p>İyi günler!</p><p>KAHEV</p>`
        };
      case "missing":
        return {
          subject: "KAHEV Burs Başvurunuzda Eksik Belgeler Var!",
          body: `<p>Merhaba,</p><p><strong>{{tarih}}</strong> tarihinde <strong>{{ogrenci}}</strong> adına yaptığınız <strong>{{id}}</strong> kodlu burs başvurunuzda eksik evraklar bulunmaktadır. Başvurunuzun değerlendirilebilmesi için aşağıda listelenen eksik evrakları <strong>29 Ekim Cuma</strong> tarihine kadar, tamamı bir mailde olacak şekilde <strong>kahevburstakip@gmail.com</strong> adresine göndermeniz gerekmektedir. Tek tek mail atılan evraklar geçersiz sayılacaktır. Mailin konusuna "{{ogrenci}} Eksik Evraklar" yazmanız gerekmektedir.</p><p><strong>Eksik Belgeler</strong></p><p>İyi günler!</p><p>KAHEV</p>`
        };
      default:
        return {
          subject: "KAHEV Burs Başvurunuz Değerlendiriliyor",
          body: `<p>Merhaba,</p><p><strong>{{tarih}}</strong> tarihinde <strong>{{ogrenci}}</strong> adına yaptığınız <strong>{{id}}</strong> kodlu burs başvurunuzun durumu <strong>değerlendiriliyor</strong> olarak güncellendi.</p><p>İyi günler!</p><p>KAHEV</p>`
        };
    }
  }

  changeStatusMessage() {
    if (this.app && this.app.statusMessage) {
      this.app.statusMessage = this.statusMessage;
    }
  }

  get editor() {
    return ClassicEditor;
  }

  editorConfig = {
    language: "tr",
    toolbar: [
      "undo",
      "redo",
      "|",
      "heading",
      "|",
      "bold",
      "italic",
      "underline",
      "strikethrough",
      "highlight",
      "subscript",
      "superscript",
      "specialCharacters",
      "|",
      "alignment",
      "numberedList",
      "bulletedList",
      "outdent",
      "indent",
      "blockQuote",
      "|",
      "removeFormat",
      "link",
      "insertTable",
      "mediaEmbed",
      "imageInsert",
      "htmlEmbed",
      "code"
    ],
    image: {
      toolbar: [
        "imageTextAlternative",
        "imageStyle:full",
        "imageStyle:side",
        "linkImage"
      ]
    },
    table: {
      contentToolbar: [
        "tableColumn",
        "tableRow",
        "mergeTableCells",
        "tableCellProperties",
        "tableProperties"
      ]
    }
  };

  async updateStatus() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const confirmDialog: any = this.$refs.confirm;
    const confirmation = await confirmDialog.open(
      "Emin misiniz?",
      "Başvuru durumunu değiştirmek istediğinizden emin misiniz?"
    );

    if (confirmation) {
      try {
        await firebase
          .firestore()
          .doc(`/apps/kahev-org/scholarshipApps/${this.appId}`)
          .update({
            status: this.app?.status,
            statusMessage: this.app?.statusMessage
          });

        this.$notify({
          type: "success",
          title: "İşlem Başarılı",
          text: "Burs başvurusu kaydedildi."
        });
      } catch (err) {
        this.$notify({
          type: "error",
          title: "Hata!",
          text: "Başvuru kaydedilemedi. Lütfen sistem yöneticisine başvurunuz."
        });

        console.error(err);
      }
    }
  }

  async getApp() {
    const appDoc = await firebase
      .firestore()
      .doc(`/apps/kahev-org/scholarshipApps/${this.appId}`)
      .get();

    this.app = appDoc.data() as ScholarshipApplication;
    this.app.id = appDoc.id;

    // Eğer kayıtlı bir mesaj varsa onu kullan
    if (!this.app.statusMessage) {
      // Kayıtlı mesaj yoksa varsayılan mesajları kullan
      this.app.statusMessage = this.statusMessage;
    }

    const scholarshipDoc = await firebase
      .firestore()
      .doc(`/apps/kahev-org/scholarships/${this.app.scholarshipId}`)
      .get();

    this.scholarship = scholarshipDoc.data() as Scholarship;
    this.scholarship.id = scholarshipDoc.id;
  }

  async del() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const confirmDialog: any = this.$refs.confirm;
    const confirmation = await confirmDialog.open(
      "Emin misiniz?",
      "Başvuruyu silmek istediğinizden emin misiniz?"
    );

    if (confirmation) {
      try {
        await firebase
          .firestore()
          .doc(`/apps/kahev-org/scholarshipApps/${this.appId}`)
          .delete();

        this.$notify({
          type: "success",
          title: "İşlem Başarılı",
          text: "Burs başvurusu silindi."
        });

        const ival = setInterval(() => {
          clearInterval(ival);

          this.$router.push({
            name: "admin-home"
          });
        }, 3000);
      } catch (err) {
        this.$notify({
          type: "error",
          title: "Hata!",
          text: "Başvuru silinemedi. Lütfen sistem yöneticisine başvurunuz."
        });

        console.error(err);
      }
    }
  }

  async init() {
    this.appId = this.$route.params.id;

    await this.getApp();
  }

  async mounted() {
    await this.init();
  }
}
